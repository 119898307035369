<template>
    <div>
        <CardBanner />
        <div v-if="loading == false">
            <div class="row mt-3" v-if="tradings.length > 0">
                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3" v-for="trading in tradings" :key="trading.id">
                    <CardLive :trading="trading" />
                </div>
            </div>
            <Empty v-else/>
        </div>
        <Loading v-else />
    </div>
</template>
<script>
import CardLive from './CardLive.vue'
import CardBanner from './CardBanner.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        CardLive,
        CardBanner
    },
    data () {
        return {
            loading: false
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('tradingLive', ['getTradings']),
        getData () {
            this.loading = true
            this.getTradings().then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('tradingLive', ['tradings'])
    }
}
</script>