<template>
    <div>
        <b-card>
            <img class="card-img rounded-0" :src="trading.image" alt="Image">

            <div class="on-live">
                {{ formatDate(trading.day) }}
            </div>

            <div class="class-title">
                {{ trading.name }}
            </div>
            <div class="class-description mt-3 mb-3">
                {{ trading.description }}
            </div>
            <div class="mt-2">
                <b-button class="w-100" :disabled="trading.is_online == 0" variant="primary" :to="{ name: 'Meet-Live', params: { id: trading.id, name: deleteSpaces(trading.name) } }">
                    View class
                </b-button>
            </div>
            
        </b-card>    
    </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
    props: ['trading'],
    methods: {
        ...mapActions('tradingLive', ['togleStatus', 'getTradings']),
        formatDate (date) {
            let today = moment().format('Y-MM-DD')
            if(this.trading.is_online == 1) {
                return 'On Live'
            }
            if(today == moment(date).format('Y-MM-DD')) {
                return 'Today'
            } else {
                return moment(date).format('Y-MM-DD')
            }
        },
        deleteSpaces (name) {
            return name.replace(/ /g, '-');
        }
    }
}
</script>
<style scoped>
    .class-title{
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
    }
    .class-description{
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
    }
    .on-live{
        background-color: rgba(255, 89, 90, 1);
        position: absolute;
        top: 30px;
        right: 30px;
        padding: 5px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }
</style>